import * as Sentry from '@sentry/vue';

import { App } from 'vue';
import { Router } from 'vue-router';

export function bootstrapSentry(app: App, router: Router) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    tunnel: '/sentry',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,

        networkDetailAllowUrls: ['/api/shows'],
        networkRequestHeaders: ['X-Custom-Header'],
        networkResponseHeaders: ['X-Custom-Header'],
      }),
      Sentry.captureConsoleIntegration(),
      Sentry.extraErrorDataIntegration(),
      Sentry.httpClientIntegration(),
    ],

    // This option is required for capturing headers and cookies.
    sendDefaultPii: true,
    tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE ?? 0,

    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  const tenant = window.tenantConfig
    ? {
        id: window.tenantConfig.id,
        title: window.tenantConfig.title,
      }
    : null;

  Sentry.setContext('tenant', tenant);

  const user = window.user
    ? {
        id: window.user.id,
        name: window.user.name,
        email: window.user.email,
      }
    : null;

  Sentry.setUser(user);
}
