import { createApp } from 'vue';

import App from './App.vue';

import { bootstrapSentry } from '$common/bootstrappers/sentry/index.ts';
import { bootstrapVue } from '$common/bootstrappers/vue/index.ts';
import { router } from '$tenant/router';

import.meta.glob(['../../assets/**']);

const app = createApp(App);

bootstrapSentry(app, router);
bootstrapVue(app);

app.use(router);
app.mount('#app');
